<template>
  <div class="auth-wrapper auth-v2">
    <b-row :style="loginStyle" class="auth-inner m-0 justify-content-center">
      <b-col lg="10" class="pt-5">
          <InitialHeader :onlyLogo="true" />
      </b-col>
      <b-col
          lg="5"
          class="d-flex align-items-center mb-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="9"
            class="px-lg-3 mx-auto auth-bg py-5 rounded"
        >
          <h2 class="mb-1 auth-title">
           Esqueceu sua senha?
          </h2>

          <b-card-text class="mb-2">
            Preencha seu e-mail e nós enviaremos as próximas instruções.
          </b-card-text>

          <b-alert v-if="alert.show" show fade class="mb-2" variant="danger">
            <div class="custom-alert">
              <span>
                <BIconExclamationCircle />

                <template v-if="typeof alert.message === 'string'">
                  {{ alert.message }}
                </template>
                <template v-else>
                  <span v-for="(value, key) in alert.message" :key="key">
                    {{ value[0] }}
                  </span>
                </template>
              </span>
            </div>
          </b-alert>

          <!-- form -->
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent="forgotPassword"
          >
            <!-- email -->
            <b-form-group
                label="E-mail"
                label-for="login-email"
            >
              <b-form-input
                  id="login-email"
                  v-model="forgot.email"
                  @focus="alert.show = false"
                  name="login-email"
                  placeholder="email@email.com"
                  autocomplete="off"
              />
              <small class="text-danger">{{ formErrors.email }}</small>
            </b-form-group>

            <!-- submit buttons -->
            <button :disabled="disabledButton" type="submit" class="btn button-form btn-block">
              {{ msgButton }}
            </button>
          </b-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <BIconChevronLeft />
              <span class="to-login">&nbsp;Volte ao Login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <FooterInitial/>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BIconChevronLeft, BInputGroupAppend, BIconExclamationCircle, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert
} from 'bootstrap-vue'
import { validation } from '@core/mixins/validation/validation'
import { messages } from "@core/mixins/validation/messages";
import LoginImage from "@/views/pages/authentication/components/LoginImage";
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue';
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue';
import { setEmailForgotPassword } from '@core/utils/store/setStore';
import { getEmailForgotPassword } from '@core/utils/store/getStore';

export default {
  title: 'Recuperação de senha',

  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BIconChevronLeft,
    BInputGroupAppend,
    BIconExclamationCircle,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    LoginImage,
    FooterInitial,
    InitialHeader
},

  mixins: [validation, messages],

  mounted() {
    this.$helpers.indexablePage();
  },

  data() {
    return {
      forgot: {
        email: getEmailForgotPassword() || '',
      },
      sideImg: require('@/assets/images/login/login-vacina-sesi-logo.jpg'),
      loginStyle:{
          'background-image': `url(${require('@/assets/custom-images/bg/bg-login.png')})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'cover'
        },
      loadingShow: true,

      disabledButton: false,
      msgButton: 'Enviar',

      formErrors: {},

      alert: {
        show: false,
        message: '',
        variant: ''
      },
    }
  },

  methods: {
    async forgotPassword() {
      if(this.validation()) {
        this.buttonLoading(true);
        await this.$http.post(this.$api.recuperarSenha(), this.forgot, { timeout: 20000 })
            .then((response) => {
              const status = response.request.status;

              if(status === 204) {
                setEmailForgotPassword(this.forgot.email)
                this.$router.push({ path: `/para-empresas/esqueci-minha-senha/verificar-email/${this.forgot.email}` });
              }
            }).catch(error => {
              if(error.response){
                this.errorHandling(error.response);
              }else{
                this.errorHandling("Tempo de espera expirado. Tente novamente ou contate o suporte.");
              }
            })
        this.buttonLoading(false);
      }
    },

    errorHandling(response) {
      let message = response.data ? response.data.errors : response;      
      
      this.showAlert(
          message,
          'danger'
      );
    },

    validation() {
      this.emailValidate(this.forgot.email);

      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
